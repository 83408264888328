@import "../../base/fn";

.weui-btn_primary {
    background-color: @weuiBtnPrimaryBg;
    &:not(.weui-btn_disabled):visited {
        color: @weuiBtnFontColor;
    }
    &:not(.weui-btn_disabled):active {
        color: @weuiBtnActiveFontColor;
        background-color: @weuiBtnPrimaryActiveBg;
    }
}
