@import "weui-font";

[class^="weui-icon_"]:before, [class*=" weui-icon_"]:before {
    margin: 0;
}
.weui-icon-success {
    font-size: 23px;
    color: #09BB07;
}
.weui-icon-waiting {
    font-size: 23px;
    color: #10AEFF;
}
.weui-icon-warn {
    font-size: 23px;
    color: #F43530;
}
.weui-icon-info {
    font-size: 23px;
    color: #10AEFF;
}

.weui-icon-success-circle {
    font-size: 23px;
    color: #09BB07;
}
.weui-icon-success-no-circle {
    font-size: 23px;
    color: #09BB07;
}
.weui-icon-waiting-circle {
    font-size: 23px;
    color: #10AEFF;
}
.weui-icon-circle {
    font-size: 23px;
    color: #C9C9C9;
}
.weui-icon-download {
    font-size: 23px;
    color: #09BB07;
}

.weui-icon-info-circle {
    font-size: 23px;
    color: #09BB07;
}

.weui-icon-safe-success {
    color: #09BB07;
}
.weui-icon-safe-warn {
    color: #FFBE00;
}

.weui-icon-cancel {
    color: #F43530;
    font-size: 22px;
}

.weui-icon-search {
    color: #B2B2B2;
    font-size: 14px;
}

.weui-icon-clear {
    color: #B2B2B2;
    font-size: 14px;
}

.weui-icon-delete {
    &.weui-icon_gallery-delete{
        color:#FFFFFF;
        font-size:22px;
    }
}

.weui-icon_msg {
    font-size: 93px;
    &.weui-icon-warn {
        color: #F76260;
    }
}
.weui-icon_msg-primary {
    font-size: 93px;
    &.weui-icon-warn {
        color: #FFBE00;
    }
}
