@import "../../base/fn";

.weui-grids {
    position: relative;
    overflow: hidden;

    &:before {
        .setTopLine(@weuiGridBorderColor);
    }
    &:after {
        .setLeftLine(@weuiGridBorderColor);
    }
}

.weui-grid {
    position: relative;
    float: left;
    padding: 20px 10px;
    width: 100% / @weuiGridColumnCount;
    box-sizing: border-box;

    &:before {
        .setRightLine(@weuiGridBorderColor);
    }
    &:after {
        .setBottomLine(@weuiGridBorderColor);
    }

    &:active {
        background-color: @weuiBgColorActive;
    }
}

.weui-grid__icon {
    width: @weuiGridIconSize;
    height: @weuiGridIconSize;
    margin: 0 auto;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    & + .weui-grid__label{
        margin-top: 5px;
    }
}

.weui-grid__label {
    display: block;
    text-align: center;
    color: @weuiTextColorTitle;
    font-size: @weuiGridFontSize;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
