@import "../../base/fn";

.weui-badge {
    display: inline-block;
    padding: .15em .4em;
    min-width: 8px;
    border-radius: 18px;
    background-color: #F43530;
    color: #FFFFFF;
    line-height: 1.2;
    text-align: center;
    font-size: 12px;
    vertical-align: middle;
}
.weui-badge_dot {
    padding: .4em;
    min-width: 0;
}