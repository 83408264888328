@import "../../base/fn";

.weui-loadmore{
    width: 65%;
    margin:1.5em auto;
    line-height: 1.6em;
    font-size:14px;
    text-align: center;
}
.weui-loadmore__tips{
    display: inline-block;
    vertical-align: middle;
}

.weui-loadmore_line{
    border-top:1px solid @weuiLineColorLight;
    margin-top:2.4em;
    .weui-loadmore__tips{
        position: relative;
        top:-.9em;
        padding:0 .55em;
        background-color: #FFFFFF;
        color:@weuiTextColorGray;
    }
}
.weui-loadmore_dot{
    .weui-loadmore__tips{
        padding:0 .16em;
        &:before{
            content: " ";
            width:4px;
            height:4px;
            border-radius:50%;
            background-color: @weuiLineColorLight;
            display: inline-block;
            position: relative;
            vertical-align: 0;
            top:-.16em;
        }
    }
}