@import "../../../base/fn";

.weui-check__label {
    .setTapColor();
    &:active {
        background-color: #ECECEC;
    }
}

.weui-check{
    position: absolute;
    left: -9999em;
}
