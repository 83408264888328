@import "../../base/fn";

.weui-cell_switch{
    padding-top: (@weuiCellHeight - @weuiSwitchHeight) / 2;
    padding-bottom: (@weuiCellHeight - @weuiSwitchHeight) / 2;
}
.weui-switch{
    appearance: none;
}
.weui-switch,
.weui-switch-cp__box{
    position: relative;
    width: 52px;
    height: @weuiSwitchHeight;
    border: 1px solid #DFDFDF;
    outline: 0;
    border-radius: 16px;
    box-sizing: border-box;
    background-color: #DFDFDF;
    transition: background-color .1s, border .1s;

    &:before{
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: @weuiSwitchHeight - 2;
        border-radius: 15px;
        background-color: #FDFDFD;
        transition:transform .35s cubic-bezier(0.45, 1, 0.4, 1);
    }
    &:after{
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: @weuiSwitchHeight - 2;
        height: @weuiSwitchHeight - 2;
        border-radius: 15px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        transition:transform .35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
    }
}
.weui-switch:checked,
.weui-switch-cp__input:checked ~ .weui-switch-cp__box{
    border-color: #04BE02;
    background-color: #04BE02;
    &:before {
        transform: scale(0);
    }
    &:after {
        transform: translateX(20px);
    }
}

// 兼容IE Edge的版本
.weui-switch-cp__input{
    position: absolute;
    left: -9999px;
}
.weui-switch-cp__box{
    display: block;
}