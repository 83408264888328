@import "../../base/fn";

.weui-media-box {
    padding: 15px;
    position: relative;
    &:before {
        .setTopLine(@weuiLineColorLight);
        left: 15px;
    }
    &:first-child {
        &:before {
            display: none
        }
    }

    a&{
        color:#000000;
        .setTapColor();
        &:active{
            background-color:#ECECEC;
        }
    }
}
.weui-media-box__title {
    font-weight: 400;
    font-size: 17px;
    .ellipsis();
    word-wrap: break-word;
    word-break: break-all;
}
.weui-media-box__desc {
    color: @weuiTextColorGray;
    font-size: 13px;
    line-height: 1.2;
    .ellipsisLn(2);
}
.weui-media-box__info {
    margin-top: 15px;
    padding-bottom: 5px;
    font-size: 13px;
    color: #CECECE;
    line-height: 1em;
    list-style: none;
    overflow: hidden;
}
.weui-media-box__info__meta {
    float: left;
    padding-right: 1em;
}
.weui-media-box__info__meta_extra {
    padding-left: 1em;
    border-left: 1px solid #CECECE;
}
.weui-media-box_text {
    .weui-media-box__title {
        margin-bottom: 8px;
    }
}
.weui-media-box_appmsg {
    display: flex;
    align-items: center;
    .weui-media-box__hd {
        margin-right: .8em;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
    .weui-media-box__thumb {
        width: 100%;
        max-height: 100%;
        vertical-align: top;
    }
    .weui-media-box__bd {
        flex: 1;
        min-width: 0;
    }
}
.weui-media-box_small-appmsg {
    padding: 0;
    .weui-cells {
        margin-top: 0;
        &:before {
            display: none;
        }
    }
}