@import "../../base/fn";

.weui-article {
    padding: 20px 15px;
    font-size: 15px;
    section {
        margin-bottom: 1.5em;
    }
    h1 {
        font-size: 18px;
        font-weight:400;
        margin-bottom: .9em;
    }
    h2 {
        font-size: 16px;
        font-weight:400;
        margin-bottom: .34em;
    }
    h3 {
        font-weight:400;
        font-size: 15px;
        margin-bottom: .34em;
    }
    * {
        max-width: 100%;
        box-sizing: border-box;
        word-wrap: break-word;
    }
    p {
        margin: 0 0 .8em;
    }
}