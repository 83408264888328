@import "../../base/fn";

.weui-btn_disabled {
    color: @weuiBtnDisabledFontColor;
    &.weui-btn_default {
        color: @weuiBtnDefaultDisabledFontColor;
        background-color: @weuiBtnDefaultDisabledBg;
    }
    &.weui-btn_primary {
        background-color: @weuiBtnPrimaryDisabledBg;
    }
    &.weui-btn_warn {
        background-color: @weuiBtnwarnDisabledBg;
    }
}
