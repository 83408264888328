@import "../../base/fn";

.weui-cell_access {
    .setTapColor();
    color: inherit;
    &:active {
        background-color: #ECECEC;
    }
    .weui-cell__ft {
        padding-right: 13px;
        position: relative;
        &:after {
            content: " ";
            .setArrow(right, 6px, #C8C8CD, 2px);
            position: absolute;
            top: 50%;
            margin-top: -4px;
            right: 2px;
        }
    }
}
.weui-cell_link{
    color: @weuiLinkColorDefault;
    font-size: 14px;

    // 由于weui-cell:first-child的:before为隐藏，所以这里要重新显示出来
    &:first-child{
        &:before{
            display: block;
        }
    }
}
