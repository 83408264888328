@import "../../base/fn";

.weui-cells {
    margin-top: @weuiCellsMarginTop;
    background-color: @weuiCellBg;
    line-height: @weuiCellLineHeight;
    font-size: @weuiCellFontSize; //cell中间有效高度23px，跟客户端默认图标尺寸一致

    overflow: hidden; //因为每个cell的border使用before元素left搞的，ie下伪元素的containing block估计跟标准不同，在cell上用oh不生效

    // onepx
    position: relative;
    &:before {
        .setTopLine(@weuiCellBorderColor);
    }
    &:after {
        .setBottomLine(@weuiCellBorderColor);
    }
}

.weui-cells__title {
    margin-top: .77em; // 15px - 行高
    margin-bottom: .3em; // 8px - 行高
    padding-left: @weuiCellGapH;
    padding-right: @weuiCellGapH;
    color: @weuiTextColorGray;
    font-size: @weuiCellTipsFontSize;

    & + .weui-cells {
        margin-top: 0;
    }
}

.weui-cells__tips {
    margin-top: .3em; // 8px - 行高
    color: @weuiTextColorGray;
    padding-left: @weuiCellGapH;
    padding-right: @weuiCellGapH;
    font-size: @weuiCellTipsFontSize;
}

.weui-cell {
    padding: @weuiCellGapV @weuiCellGapH;
    position: relative; //这个是为了兼容cells容器onepx方案被before挡住而做的
    display: flex;
    align-items: center;
    &:before {
        .setTopLine(@weuiCellBorderColor);
        left: @weuiCellGapH;
    }
    &:first-child {
        &:before {
            display: none;
        }
    }
}
.weui-cell_primary{
    align-items: flex-start;
}
.weui-cell__bd{
    flex: 1;
}
.weui-cell__ft {
    text-align: right;
    color: @weuiTextColorGray;
}